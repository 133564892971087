// This file is auto generated from scripts/build/gen_files_by_env.cjs
// Current DEV_ENV=production

import * as modclub from "../../declarations/modclub";
import * as modclub_assets from "../../declarations/modclub_assets";
import * as rs from "../../declarations/rs";
import * as vesting from "../../declarations/vesting";
import * as wallet from "../../declarations/wallet";
import * as airdrop from "../../declarations/airdrop";

export { modclub, modclub_assets, rs, vesting, wallet, airdrop };
